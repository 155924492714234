* {
    box-sizing: border-box;
  }

body {
    background-color: rgb(40, 37, 53);
    width: 100vw;
    /*min-height: 100vh;
    min-height: -webkit-fill-available; */
    /*min-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));*/
    min-height: 100vh;
    position: relative;

    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Roboto","HelveticaNeue-Light",sans-serif;
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    body {
        min-height: -webkit-fill-available;
    }
 }

.fullscreen {
    width: 100vw;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
}

/*
html {
    min-height: calc(100vh - env(safe-area-inset-bottom));
}

.fullscreen {
    min-height: 100vh;
    min-height: -webkit-fill-available;
}
*/

#root
{
    flex-grow: 1;
    position: relative;
    display: flex;
}

#root > div
{
    flex-grow: 1;
}

.swiper {
    width: 100%;
    height: 100%;
/*    background-color: red;
*/
  }
  
  .swiper-slide {
/*    text-align: center;
    font-size: 18px;
  */
    /* Center slide text vertically */
/*   display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color:blue;
*/
    width: 90% !important;
    max-width: 325px;
    padding-bottom: 20px;
  }
  
/*  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
*/